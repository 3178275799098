@font-face {
  font-family: 'THICCCBOI';
  src: url('/public/fonts/THICCCBOI/THICCCBOI-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'THICCCBOI';
  src: url('/public/fonts/THICCCBOI/THICCCBOI-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'THICCCBOI';
  src: url('/public/fonts/THICCCBOI/THICCCBOI-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'THICCCBOI';
  src: url('/public/fonts/THICCCBOI/THICCCBOI-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'THICCCBOI';
  src: url('/public/fonts/THICCCBOI/THICCCBOI-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'THICCCBOI';
  src: url('/public/fonts/THICCCBOI/THICCCBOI-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'THICCCBOI';
  src: url('/public/fonts/THICCCBOI/THICCCBOI-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'THICCCBOI';
  src: url('/public/fonts/THICCCBOI/THICCCBOI-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}
