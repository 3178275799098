.ll-markdown p {
    margin-bottom: 16px;
}

.ll-markdown ul {
    margin-bottom: 16px;
    padding-left: 32px;
}

.ll-markdown ol {
    margin-bottom: 16px;
    padding-left: 32px;
}