/* .annotation {
    background-color: lightyellow;
    border-bottom: 2px solid yellow;
    cursor: pointer;
} */
.ProseMirror:focus {
    outline: none;
}

.annotation.grammarError {
    background-color: rgb(255, 255, 161);
    border-bottom: 2px solid rgb(227, 227, 62);
    cursor: pointer;
}

.annotation.spellingError {
    background-color: rgb(171, 250, 171);
    border-bottom: 2px solid green;
    cursor: pointer;
}

.annotation.rephrase {
    background-color: rgb(250, 176, 176);
    border-bottom: 2px solid red;
    cursor: pointer;
}

.annotation.selected {
    background-color: lightblue;
}

.annotation-comment {
    margin-bottom: 10px;
    padding: 5px;

    /* background-color: grey; */
    cursor: pointer;
}

.annotation-comment.selected {
    color: green;
    font-weight: bold;
    border-color: green;
}